import React from "react";
// import './Product.css';
import "antd/dist/antd.css";
import { PageHeader } from "antd";
import { ProductList } from "../config/Configs.js";
import {
  isAndroid,
} from "react-device-detect";
import ARButton from "../components/ARButton"
class Product extends React.Component {
  render() {
    const productId = this.props.match.params.productId;
    const product = ProductList[productId];
    return (
      <div>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          onBack={() => window.history.back()}
          title={product.name}
        />

        {product.type == "3D" ? (
          <div className="tk-container">
            <div
              id="player"
              style={{
                height: 400,
                width: "96%",
                marginLeft: "auto",
                marginRight: "auto",
                background: "#F0F2F5",
              }}
            ></div>
            <br/>
            {/* <ARButton usdz={product.usdz}/>  */}
          </div>
        ) : (
          <p>This is a 2D product</p>
        )}
      </div>
    );
  }

  componentDidMount() {
    const productId = this.props.match.params.productId;
    const product = ProductList[productId];
    console.log("Component mounted");

    // Put player here
    window
      .threekitPlayer({
        authToken: "afa7025e-53a9-483c-a876-b05effa9ca73",
        el: document.getElementById("player"),
        // stageId: '27b9cd7e-2bb2-4a18-b788-160743eb4b33',
        assetId: `${product.threekit}`,
        showConfigurator: true,
        initialConfiguration: product.config,
        showAR: true,
      })
      .then(async (api) => {
        window.player = api;
        window.configurator = await api.getConfigurator();
      });
  }
}

export default Product;
