const ProductList = {
  "1": {
    type: "3D",
    imageURL:
      "https://solutions-engineering.s3.amazonaws.com/quickdemo/art/art-thumbnail.png",
    threekit: "e0f40f6c-d917-4cf6-b7cb-e176d038bd88",
    config: {
      "Frame Finish": { assetId: "d535f519-b8e1-4f45-bbe1-359b2eacb3d3" },
    },

    name: "Custom Wall Art",
    brand: "Upload art and see the frame on your wall",
  },
  "2": {
    type: "3D",
    imageURL:
      "https://solutions-engineering.s3.amazonaws.com/quickdemo/art/tiger-thumb.png",
      usdz: "https://solutions-engineering.s3.amazonaws.com/quickdemo/art/LeTigreTest2.usdz",
    threekit: "0f30301b-0318-433e-a53a-fdc8daba6199",
    config: {},
    name: "Wall Art",
    brand: "See art on your wall with AR",
  },
};
export { ProductList };
