import React from "react";
import "antd/dist/antd.css";
import {
  MobileView,
  BrowserView,
  AndroidView,
  IOSView,
  isIOS,
  isAndroid,
  getUA,
} from "react-device-detect";
import { Button } from "antd";
import "@google/model-viewer";
import ReactGA from "react-ga";

var wxyz,
  standalone = window.navigator.standalone,
  userAgent = window.navigator.userAgent.toLowerCase(),
  safari = /safari/.test(userAgent),
  ios = /iphone|ipod|ipad/.test(userAgent);

class ARButton extends React.Component {
  state = {};

  componentDidMount() {
    this.setState({ UA: getUA });
    if (ios) {
      if (!standalone && safari) {
        wxyz = "safari";
        console.log(wxyz);
        this.setState({ browser: "safari" });
      } else if (!standalone && !safari) {
        wxyz = "iOS webview";
        console.log(wxyz);

        this.setState({ browser: "app" });
      }
    } else {
      if (userAgent.includes("wv")) {
        wxyz = "Android webview";
        console.log(wxyz);

        this.setState({ browser: "Android webview" });
      } else {
        wxyz = "Chrome";
        this.setState({ browser: "Chrome" });
      }
    }
  }

  render() {
    let arlink = isIOS ? this.props.usdz : isAndroid ? this.props.glb : null;
    let glbLink = this.props.glb;

    //const model = document.createElement('model-viewer');
    //model.src = 'https://threekit.s3.amazonaws.com/glb/BeoSound+2+Silver.glb';
    //console.log(model);
    //document.body.appendChild(model);
    return (
      <div>
        <MobileView>
          <IOSView>
            {/* <Button onClick={this.clickIos}>View in AR</Button> */}

            {getUA.includes("[LinkedInApp]") ? (
              <Button disabled={true}>
                To view in AR open in mobile browser
              </Button>
            ) : (
              <Button onClick={this.clickIos}>View in your space</Button>
            )}

            {/* {this.state.browser == "app" ? (
            
            
            ) : (
              
            )} */}
            <div style={{ display: "none" }}>
              <a href={arlink} rel="ar" id="ar-ios-link">
                <img
                  style={{ display: "none" }}
                  src="https://venturebeat.com/wp-content/uploads/2019/11/threekit-logo.png?fit=400%2C200&strip=all"
                  alt="hidden iOs ar link"
                />
              </a>
            </div>
          </IOSView>
          
        </MobileView>


      </div>
    );
  }

  clickIos = () => {
    ReactGA.initialize("UA-63905846-9");
    ReactGA.set({ dimension1: "Art Demo" });
    ReactGA.pageview(this.props.productName + " Art Demo");
    let alink = document.getElementById("ar-ios-link").click();
  };
}

export default ARButton;
